const fetchLatestUpdateContentQuery = () => {
  return `
  {
    latestUpdatesModalContentCollection(limit: 1, order: sys_publishedAt_ASC) {
     items {
       sys {
         id
         publishedAt
       }
       image {
         url
       }
       summary {
         json    
       }
       latestUpdateArticle {
         title
         slug
       }
     }
   }
 }
  `
}

export default fetchLatestUpdateContentQuery
