import { ApiInventory } from '~/lib/api-inventory'
import {
  AccountService,
  AuthService,
  BusinessConnectionsService,
  BusinessUnitService,
  CommodityService,
  CoreService,
  FileService,
  GeoService,
  HarvestService,
  LocationService,
  MovementsService,
  OrganisationService,
  RecordService,
  SearchService,
  StorageNotesService,
  StorageService,
  UsersService
} from '~/services/inventory'
import {
  BidsService,
  ExFarmPricingService,
  MarketListingService,
  MarketUpdateService,
  NewsFeedService,
  SalesContractService,
  StorageContractService,
  TargetPricesService,
  TradeAvailability,
  TradedService,
  TradePosition,
  TradeRequestsService
} from '~/services/trading'

export const createApi = nuxtContext => {
  const api = {
    account: apiModel => new AccountService(nuxtContext, apiModel),
    auth: apiModel => new AuthService(nuxtContext, apiModel),
    businessConnections: apiModel => new BusinessConnectionsService(nuxtContext, apiModel),
    businessUnit: apiModel => new BusinessUnitService(nuxtContext, apiModel),
    commodity: apiModel => new CommodityService(nuxtContext, apiModel),
    core: apiModel => new CoreService(nuxtContext, apiModel),
    default: apiModel => new ApiInventory(nuxtContext, apiModel),
    exFarmPricing: apiModel => new ExFarmPricingService(nuxtContext, apiModel),
    geo: apiModel => new GeoService(nuxtContext, apiModel),
    location: apiModel => new LocationService(nuxtContext, apiModel),
    movements: apiModel => new MovementsService(nuxtContext, apiModel),
    record: apiModel => new RecordService(nuxtContext, apiModel),
    search: apiModel => new SearchService(nuxtContext, apiModel),
    organisations: apiModel => new OrganisationService(nuxtContext, apiModel),
    file: apiModel => new FileService(nuxtContext, apiModel),
    storage: apiModel => new StorageService(nuxtContext, apiModel),
    storageNotes: apiModel => new StorageNotesService(nuxtContext, apiModel),
    users: apiModel => new UsersService(nuxtContext, apiModel),
    salesContracts: apiModel => new SalesContractService(nuxtContext, apiModel),
    traded: apiModel => new TradedService(nuxtContext, apiModel),
    storageContracts: apiModel => new StorageContractService(nuxtContext, apiModel),
    tradeAvailability: apiModel => new TradeAvailability(nuxtContext, apiModel),
    tradePosition: apiModel => new TradePosition(nuxtContext, apiModel),
    marketListing: apiModel => new MarketListingService(nuxtContext, apiModel),
    marketUpdates: apiModel => new MarketUpdateService(nuxtContext, apiModel),
    newsFeed: apiModel => new NewsFeedService(nuxtContext, apiModel),
    bids: apiModel => new BidsService(nuxtContext, apiModel),
    tradeRequests: apiModel => new TradeRequestsService(nuxtContext, apiModel),
    harvest: apiModel => new HarvestService(nuxtContext, apiModel),
    targetPrices: apiModel => new TargetPricesService(nuxtContext, apiModel)
  }

  return api
}

export default function (nuxtContext, inject) {
  const api = createApi(nuxtContext)

  // Inject to context as $api
  // Note: we already have $Api with a capital letter which will be deprecated
  // when inventory and logistics are merged
  inject('api', api)
}
