import { contentfulSpaces } from '~/lib/enums/contentful'
import { buildFetchOptions, fetchUrl } from '~/services/contentful/contentful'
import fetchLatestUpdateContentQuery from '~/services/contentful/queries/fetchLatestUpdateContentQuery'

export type LatestUpdatesModalContentResponse = {
  sys: {
    id: string
    publishedAt: string
  }
  image: {
    url: string
  }
  summary: {
    json: object
  }
  LatestUpdateArticle: {
    title: string
    slug: string
  }
}

export const fetchLatestUpdateModalContent = async (): Promise<LatestUpdatesModalContentResponse> => {
  const query = fetchLatestUpdateContentQuery()

  const fetchOptions = buildFetchOptions(query, contentfulSpaces.HELP_CENTRE)

  const response = await fetch(fetchUrl(contentfulSpaces.HELP_CENTRE), fetchOptions)

  const responseJson = await response.json()

  return responseJson.data.latestUpdatesModalContentCollection.items[0] ?? {}
}
