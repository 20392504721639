import { computed } from '@nuxtjs/composition-api'

import { offlineDataAuth } from '~/lib/enums/cookies/offline-data-keys.js'
import { loginActionsEnum } from '~/lib/enums/login-actions'
import { modulesMap, permissionsMap } from '~/lib/enums/permissions.config'
import { hasAppWebForcedLogout, hasHideTradingInventoryAccess } from '~/lib/utility/feature-flags'
import { getOfflineStorage } from '~/lib/utility/offline-storage'

export default async function ({ route, store, $log, redirect, from, ...rest }) {
  const getHasModule = store.getters['inventory/auth/getHasModule']
  // Add "route name" and permissions to guard page. Remember to update if route changes.
  const permissionsByRouteName = {
    'inventory-internal-business-accounts-index': permissionsMap.admin,
    'trading-market-listing-marketListingId-bids': permissionsMap.viewTradingListings,
    'trading-market-listing': permissionsMap.viewTradingListings,
    'trading-availability': permissionsMap.viewTradingPosition
  }

  if (!store.state.inventory?.auth?.accessToken) {
    await store.dispatch('inventory/auth/loadExistingTokens')
  }

  const app = store.getters['inventory/app/getApp']

  // app.isReady is currently forced to be true until we have a way of figuring
  // out from BE if the platform is in maintence mode
  // if (!app.isReady) {

  // Currently no way of telling if inventory is in maintence
  // try {
  //   const pingResponse = await $network.checkConnection(true)

  //   // If we are in maintenance mode then most likely our APIs are down so no point trying to connect
  //   if (pingResponse.isMaintenanceModeEnabled) {
  //     return
  //   }
  // } catch (error) {
  //   // Stub - internet offline
  //   // }
  // }

  // On native we cache the org details for a faster startup time
  if (app.isReady || (app.isNative && !!app.config.orgName)) {
    // Already run default layout fetch, skipping initial setup
  } else {
    // app = store.getters['inventory/app/getApp']
  }

  const guestPaths = ['/inventory/auth']

  const loginPath = computed(() => store.getters['inventory/auth/loginPath'])

  // If user isn't logged in redirect them to login page if the current path isn't a guest path

  if (!app.isAuth && !guestPaths.some(guestPath => route.path.includes(guestPath))) {
    store.commit('navigation/setUnauthenticatedPath', route.fullPath)
    const routeQuery = route.query

    routeQuery.redirect = route.path

    if (routeQuery.redirect.includes('/inventory/manage/account')) {
      routeQuery.verifyUpdate = true
    }

    if (routeQuery.token) {
      routeQuery.loginAction = loginActionsEnum.TOKEN_LOGIN
    }

    const existingRefreshToken = getOfflineStorage(offlineDataAuth.FARMTO_INVENTORY_REFRESH_TOKEN)

    if (!existingRefreshToken && !!hasAppWebForcedLogout) {
      return redirect({ path: '/inventory/auth/login', query: routeQuery })
    }
  }

  if (app.isAuth) {
    const { redirect: redirectPath } = route.query
    const isGraindexRedirect = redirectPath === 'gdx'
    const shouldRedirectOnLogin = redirectPath && !isGraindexRedirect

    if (route.path.includes('/inventory/auth/login') && shouldRedirectOnLogin) {
      return redirect({ path: redirectPath, query: route.query })
    }

    if (route.path.includes('/inventory/auth/login/create-account')) {
      return redirect(loginPath.value)
    }

    if (
      hasHideTradingInventoryAccess &&
      route.path.includes('/inventory/auth/login') &&
      !getHasModule(modulesMap.inventoryCore) &&
      !getHasModule(modulesMap.tradingCore) &&
      !getHasModule(modulesMap.tradingBuyers)
    ) {
      return redirect('/inventory/manage/account')
    }

    if (
      !getHasModule(modulesMap.inventoryCore) &&
      (getHasModule(modulesMap.tradingCore) || getHasModule(modulesMap.tradingBuyers)) &&
      route.path === '/inventory'
    ) {
      return redirect('/trading/market-insights')
    }

    if (!store.getters['inventory/auth/getCurrentOrg']?.vetted) {
      store.dispatch('inventory/auth/getCurrentProfile')
    }

    const userCountryISO = store.getters['inventory/app/getCurrentOrgCountryInfo'].iso

    store.dispatch('inventory/app/fetchCoreConfig', {
      countryCode: userCountryISO
    })

    store.dispatch('locale/switchLocaleByCountryCode', userCountryISO)

    if (!store.getters['inventory/record/getCommoditiesByOrganisation'].length && !app.isClientLoaded) {
      store.dispatch('inventory/record/fetchCommoditiesByOrganisation')
    }
  }

  if (route.path === '/') {
    return redirect('/inventory')
  }

  // Check user has permissions for page
  // Use definePageMeta once using Vue/Nuxt 3

  const requiredPermissions = route.meta[0]?.requiredPermissions || permissionsByRouteName[route.name]

  if (requiredPermissions) {
    const hasPermission = store.getters['inventory/auth/getHasPermission'](requiredPermissions)

    if (!hasPermission) {
      // TODO determine fallback page to send user after trying to access forbidden areas
      return redirect(from && from.path !== route.path ? from : '/inventory')
    }
  }
}
