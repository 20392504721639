import { flagPayloads } from '~/services/posthog'

export const getFlagPayload = (featureKey: string) => {
  return flagPayloads[featureKey]
}

export const hasEditRecordVarietyField = getFlagPayload('edit-record-variety-field')
export const hasPodCsvUploadDownload = getFlagPayload('pod-csv-upload-download')
export const hasPodDataDownloadTimeframe = getFlagPayload('pod-data-download-timeframe')
export const hasAccessPendingAccount = getFlagPayload('access-pending-account')
export const hasBuyerListingDetailsPageRedesign = getFlagPayload('buyer-listing-details-page-redesign')
export const hasGettingStarted = getFlagPayload('getting-started')
export const hasAutoEndListings = getFlagPayload('auto-end-listings')
export const hasShowUnsavedChangesModal = getFlagPayload('show-unsaved-changes-modal')
export const hasAutoEndV2 = getFlagPayload('auto-end-v2')
export const hasClosedBidsStatusUpdate = getFlagPayload('closed-bids-status-update')
export const hasLoadingSkeletons = getFlagPayload('loading-skeletons')
export const hasHarvestYearAttributeInputUpdates = getFlagPayload('harvest-year-attribute-input-updates')
export const hasMarketInsightsHighlightsCarousel = getFlagPayload('market-insights-highlights-carousel')
export const hasDraftListings = getFlagPayload('draft-listings')
export const hasBuyerAssuranceUpdate = getFlagPayload('buyer-assurance-update')
export const hasMovementComponentPostListing = getFlagPayload('movement-component-post-listing')
export const hasBuyerHideOrgToggle = getFlagPayload('buyer-hide-org-toggle')
export const hasAsAvailable = getFlagPayload('as-available')
export const hasAppWebForcedLogout = getFlagPayload('app-web-forced-logout')
export const hasAdvancePayCalculatorRefactor = getFlagPayload('advance-pay-calculator-refactor')
export const hasListingSupportComponent = getFlagPayload('listing-support-component')
export const hasCommoditySearch = getFlagPayload('commodity-fuzzy-search')
export const hasTargetPricesExplainer = getFlagPayload('target-prices-explainer')
export const hasTermsAndConditionsModalRefactor = getFlagPayload('terms-and-conditions-modal-refactor')
export const hasListingsRecentCommodities = getFlagPayload('listings-recent-commodities')
export const hasBuyerFormRedesign = getFlagPayload('buyer-form-redesign')
export const hasUpdateLoginImages = getFlagPayload('update-login-images')
export const hasTargetPricesCalculate = getFlagPayload('target-prices-calculate')
export const hasRegionSelectorAlertPreferences = getFlagPayload('region-selector-alert-preferences')
export const hasListingBasePlusPremiumOption = getFlagPayload('listings-base-plus-premium-option')
export const hasSellerBuyerMenuUpdates = getFlagPayload('seller-buyer-menu-update')
export const hasPasswordSecurityRulesUpdate = getFlagPayload('password-security-rules-update')
export const hasListingClosedPrompt = getFlagPayload('listing-closed-prompt')
export const hasMagicSignInFlow = getFlagPayload('magic-sign-in-flow')
export const hasBuyerListingsAllWanted = getFlagPayload('buyer-listings-all-wanted')
export const hasBuyerAlertBidSameOrg = getFlagPayload('buyer-alert-bid-same-org')
export const hasImpersonateUserLevel = getFlagPayload('impersonate-user-level')
export const hasClearerSpecificationChanges = getFlagPayload('clearer-specification-changes')
export const hasUpdateVarietySelection = getFlagPayload('update-variety-selection')
export const hasRequestToReopenPrice = getFlagPayload('request-to-reopen-bid')
export const hasHelpSectionUpdates = getFlagPayload('help-section-updates')
export const hasSignupCommodityPreferences = getFlagPayload('signup-commodity-preferences')
export const hasHideTradingInventoryAccess = getFlagPayload('hide-trading-inventory-access')
export const hasLatestUpdates = getFlagPayload('latest-updates-work')
export const hasRedesignTeamMembers = getFlagPayload('redesign-team-members')
